import { HashLink } from "react-router-hash-link"
import styled from "styled-components"

export const SpaceSection = styled.section`
    padding: 9rem 1.5rem 25rem 1.5rem;
    background: linear-gradient(180deg, #130633 56%, #0568BE);
    position: relative;
    margin: 5rem 0;
    overflow: hidden;

    &.reverse {
        background: linear-gradient(180deg, #0568BE, #130633 60%);
    }

    &::before, &::after {
        content: '';
        position: absolute;
        background-color: white;
        width: 110%;
        height: 250px;
        left: -5%;
        rotate: 4deg;
    }

    &::before {
        top: -157px;
    }

    &::after {
        bottom: -170px;
    }
`

export const Header = styled.div`
    background-color: #130633;
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    position: sticky;
    top: 0;
    z-index: 100;
`

export const WebBoostLogoContainer = styled(HashLink)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
`

export const WebBoostLink = styled(HashLink)`
    margin: auto;
    text-decoration: none;
`

export const Image = styled.img`
    width: 100%;
`

export const Title = styled.h2`
    &.header {
        color: white;
        font-size: 2.5rem;
        margin: auto;
    }
    &.title {
        text-align: center;
        margin: 4rem auto;
        font-size: 2rem;
    }
`

export const SubTitle = styled.h3`
    padding: 2rem 0 2rem 2rem;
`

export const Paragraph = styled.p`
    padding: 1rem 2rem 2rem 2rem;
    &.end {
        padding: 1rem 2rem 0 2rem;
    }
    &.second {
        padding: 0 2rem 2rem 2rem;
    }
`

export const List = styled.ul`
    padding-left: 4rem;
    margin: 0;
    line-height: 1.4rem;
`
