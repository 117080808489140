import styled from "styled-components"
import React, { useState } from "react"

const FoldableBarContainer = styled.div`
    width: 100%;
    margin: 1rem 0;
`

const FoldableBarTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;
    background-color: #FFBE00;
    border-bottom: 1px solid #F0F0F0;
    border-radius: 7px;
`

const FoldableBarTitle = styled.h3`
    font-family: "Din Next Normal", sans-serif;
    font-weight: 500;
    font-size: 1.8rem;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        font-size: 1.4rem
    }

    @media only screen and (max-width : 767px) {
        font-size: 1.1rem;
        text-align: start;
    }
`

const IconContainer = styled.div`
    width: 20px;
    height: 20px;
    min-width: 20px;
    transform: rotate(0);
    transition: all linear 300ms;
    svg {
        width: 100%;
    }

    &.rotate {
        transform: rotate(180deg);
    }
`

const FoldableBarContent = styled.div`
    max-height: 0;
    overflow: hidden;
    transition: all 500ms cubic-bezier(0, 0.51, 0.26, 1.01);
    
    
    &.isVisible {
        max-height: 30rem;
        transition: all 750ms 600ms cubic-bezier(0, 0.51, 0.26, 1.01);
    }
    & > * {
        margin-bottom: 1rem;
    }
`

const ImageContainer = styled.div`
    width: 50px;
    margin: 0 15px 0 0;

    @media only screen and (max-width: 767px) {
        width: 35px;
        min-width: 35px;
    }
`

const Image = styled.img`
    width: 100%;
`

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`
const Content = styled.p`
    padding: 1.25rem;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        font-size: 1.2rem
    }
`

function FoldableBar({ title, content, icon, isVisible, setIsVisible, index, image, className }) {

    return (
        <FoldableBarContainer className={className}>
            <FoldableBarTitleContainer onClick={() => { isVisible ? setIsVisible(null) : setIsVisible(index) }}>
                <Wrapper>
                    <ImageContainer>
                        <Image src={image} alt="image d'une planète"/>
                    </ImageContainer>
                    <FoldableBarTitle>{title}</FoldableBarTitle>
                </Wrapper>
                
                <IconContainer className={isVisible ? "rotate" : ""}>
                    {React.cloneElement(icon, {active: isVisible ? 1 : 0})}
                </IconContainer>
            </FoldableBarTitleContainer>

            <FoldableBarContent className={isVisible ? "isVisible" : ""}>
                <Content>{content}</Content>
            </FoldableBarContent>
        </FoldableBarContainer>
    )
}

export default FoldableBar