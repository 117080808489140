import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import i18next from "i18next"
import { useGSAP } from "@gsap/react"
import client1 from "../../assets/BAHYAssociates.png"
import client2 from "../../assets/altRHConsult.png"
import client3 from "../../assets/casaTropical.png"
import client4 from "../../assets/atelierCCS.png"
import client5 from "../../assets/digital-college.png"
import client6 from "../../assets/ecephas.png"
import client7 from "../../assets/focaly.png"
import client8 from "../../assets/prepavenir.png"
import gsap from 'gsap'

const Section = styled.section`
`

const CustomersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const IconContainer = styled.div`
    width: 25%;
    box-sizing: border-box;
    padding: 1.5rem 3rem;
    text-align: center;

    @media only screen and (max-width : 1223px) {
        padding: 1.5rem;
    }
    @media only screen and (max-width : 767px) {
        width: 50%;
        padding: 1rem;
    }
`

const ImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    height: 200px;
`

const Image = styled.img`
    max-width: 100%;
`

function CustomerSection() {
    useGSAP(() => {

        gsap.from('.customer-container', {
            x: -100,
            duration: 1,
            delay: 1,
            opacity: 0,
            stagger: {
                amount: 1
            },
            scrollTrigger: {
                trigger: '.customers-container',
                start: "top 65%",
            }
        })
    })
    const data = [client1, client2, client3, client4, client5, client6, client7, client8 ]
    return (
        <Section>
            <SectionTitle label={i18next.t("customer-section-title")} id="customer-title" />
            <CustomersContainer className="customers-container">
                {data.map((client, index) => (
                    <IconContainer className="customer-container" key={index}>
                        <ImageContainer>
                            <Image src={client} alt="logo client" />
                        </ImageContainer>
                    </IconContainer>
                ))}
            </CustomersContainer>
        </Section>
    )
}

export default CustomerSection