import styled from "styled-components"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import MobileNavigation from "../components/MobileNavigation"
import { GlobalContext } from "../utils/global"
import { useContext, useLayoutEffect } from "react"
import ConditionsAcceptanceBar from "../components/ConditionsAcceptanceBar"
import planet from "../assets/planetEvo6.svg"
import { useGSAP } from "@gsap/react"
import gsap from 'gsap'
import { articles } from "../utils/data.js"
import { useParams } from "react-router-dom"
import { Container, ArticleProposalContainer, ArticleImageContainer, ProposalContainer, ProposalTitle, ArticleImage, ArticleLink, ArticleProposal, ArticleProposalTitle, SmallStarElt, BigStarElt } from "./BlogPage.jsx"

const ArticleContainer = styled.article`
    width: 70%;
    padding: 3rem 1rem;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
    }
    @media only screen and (max-width : 767px) {
        width: 100%;
        padding: 9rem 1rem 3rem 1rem;
    }
`

const ArticleTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
`

const ArticleTitle = styled.h1`
    margin: 0 3rem;
    text-transform: uppercase;
    font-size: 2.5rem;
    text-align: center;

    @media only screen and (max-width : 767px) {
        margin: 0 1rem;
        font-size: 1.5rem;
    }
`

const ArticleContent = styled.p`
    margin-bottom: 2rem;
`

function ArticlePage() {
    const { device } = useContext(GlobalContext)
    const { articleTitle } = useParams()
    const currentArticle = articles.find(article => article.urlTitle === articleTitle)

    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });

    useGSAP(() => {

        gsap.from('.article-anim', {
            duration: 1,
            delay: 1,
            opacity: 0,
            stagger: {
                amount: 1
            }
        })
        gsap.from('.news-anim', {
            duration: 1,
            delay: 2,
            opacity: 0,
        })
    })

    return (
        <>
            { device === 'mobile' && <MobileNavigation /> }
            { (device === 'tablet' || device === 'desktop') && <Navigation /> }

            <Container>
                    <ArticleContainer>
                        <ArticleImageContainer className="article-anim">
                            <ArticleImage src={ currentArticle.image } alt={ currentArticle.title } />
                        </ArticleImageContainer>
                        
                        <ArticleTitleContainer className="article-anim">
                            <ArticleImageContainer className="planet">
                                <ArticleImage className="planet" src={ planet } alt="Image d'une planète" />
                            </ArticleImageContainer>

                            <ArticleTitle>{ currentArticle.title }</ArticleTitle>

                            <ArticleImageContainer className="planet">
                                <ArticleImage className="planet" src={ planet } alt="Image d'une planète" />
                            </ArticleImageContainer>
                        </ArticleTitleContainer>
                        
                        { currentArticle.content.map((text, index) => (
                            <ArticleContent className="article-anim" key={ index } dangerouslySetInnerHTML={{__html: text}}></ArticleContent>
                        ))}
                    </ArticleContainer>

                <ArticleProposalContainer className="news-anim">
                    <SmallStarElt className="one"/>
                    <SmallStarElt className="two"/>
                    <SmallStarElt className="three"/>
                    <SmallStarElt className="four"/>
                    <SmallStarElt className="five"/>
                    <SmallStarElt className="six"/>
                    <SmallStarElt className="seven"/>
                    <SmallStarElt className="eight"/>
                    <SmallStarElt className="nine"/>

                    <BigStarElt className="one"/>
                    <BigStarElt className="two"/>
                    <BigStarElt className="three"/>
                    <BigStarElt className="four"/>
                    <BigStarElt className="five"/>
                    <BigStarElt className="six"/>
                    <BigStarElt className="seven"/>
                    <BigStarElt className="eight"/>
                    <BigStarElt className="nine"/>
                    <BigStarElt className="ten"/>

                    <ProposalTitle>A la une</ProposalTitle>

                    <ProposalContainer>
                        {articles.map((art, index) => (
                            <ArticleProposal key={ index }>
                                <ArticleLink to={ `/blog/${ art.urlTitle }` }>
                                    <ArticleImageContainer className="proposal">
                                        <ArticleImage src={ art.image } alt={ art.title } />
                                    </ArticleImageContainer>
                                    <ArticleProposalTitle>{ art.title }</ArticleProposalTitle>
                                </ArticleLink>
                            </ArticleProposal>
                        ))}
                    </ProposalContainer>
                </ArticleProposalContainer>
            </Container>
            <ConditionsAcceptanceBar />
            <Footer />
        </>
    )
}

export default ArticlePage