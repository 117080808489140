import styled from "styled-components"
import Planet from '../assets/planet.svg'
import Wave from '../assets/wave.svg'
import HeaderButton from "./HeaderButton"
import orion from "../assets/orion.svg"
import casiope from "../assets/casiope.svg"
import bigbear from "../assets/bigbear.svg"
import sun from "../assets/sun.svg"
import { ReactComponent as SmallStar } from "../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../assets/bigStar.svg"
import i18next from "i18next"
import { useGSAP } from "@gsap/react"
import gsap from 'gsap'
import { useEffect, useRef } from "react"

const HeaderContainer = styled.section`
    background: linear-gradient(180deg, #130633 36%, #0568BE);
    padding: 7rem 5rem 20rem 5rem;
    position: relative;

    @media only screen and (max-width : 1024px) {
        padding: 7rem 5rem 14rem 5rem;
    }

    @media only screen and (max-width : 767px) {
        padding: 12rem 1.5rem 7rem 1.5rem;
        margin-top: 78px;
    }
`

const ContentContainer = styled.div`
    width: 65%;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @media only screen and (max-width : 767px) {
        width: 100%;
        position: relative;
        z-index: 2;
    }
`
const TextContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    &.title {
        height: 6rem;
        margin: 0 0 2rem 0;
    }

    &.catchphrase {
        height: 6rem;
        margin: 0 0 2rem 0;
    }

    &.description {
        height: 9.5rem;
        margin: 0 0 3rem 0;
    }

    @media only screen and (min-width: 768px) and (max-width: 964px) {
        &.title {
            height: 10rem;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        &.catchphrase {
            height: 8rem;
            margin: 0 0 2rem 0;
        }

        &.description {
            height: 15rem;
        }

        &.title {
            margin: 0 0 2rem 0;
        }
    }

    @media only screen and (max-width: 767px) {
        &.description {
            margin: 0 0 3rem 0;
        }

        &.title, &.catchphrase {
            margin: 0 0 2rem 0;
        }
    }
`

const Title = styled.h1`
    font-size: 3rem;
    width: 100%;

    @media only screen and (max-width : 767px) {
        font-size: 2rem;
    }
`

const Slogan = styled.span`
    font-weight: bold;
    font-size: 1.7rem;
    width: 100%;
    left: 0;

    @media only screen and (max-width : 767px) {
        font-size: 1.3rem;
    }
`

const Text = styled.p`
    font-size: 1.3rem;

    @media only screen and (max-width : 767px) {
        font-size: 1rem;
    }
`

const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    height: 6rem;
    overflow: hidden;
    position: relative;

    @media only screen and (min-width : 1025px) {
        & button:nth-child(1) {
            left: 0;
        }

        & button:nth-child(2) {
            right: 0;
        }
    }

    @media (max-width: 1340px) {
        width: 100%;
    }

    @media only screen and (max-width : 1024px) {
        flex-direction: column;
        height: 12rem;
        
        & button {
            left: 0;
            right: 0;
            margin: auto;
        }

        & button:nth-child(1) {
            top: 0;
        }

        & button:nth-child(2) {
            bottom: 0;
        }
    }
`

const Image = styled.img`
    position: absolute;
    &.planet {
        right: 10%;
        top: 13rem;
        rotate: -34deg;
        z-index: 1;
    }

    &.bottom {
        bottom: -1px;
        left: 0;
        transform: rotateY(180deg);
    }

    &.casiope {
        top: 54%;
        right: 16%;
        rotate: 29deg;
        width: 16%;
    }

    &.bigbear {
        bottom: 6%;
        left: 31%;
        rotate: 57deg;
        width: 15%;
    }

    &.orion {
        top: 0;
        left: 3rem;
        width: 15%;
    }
    &.sun {
        top: 10%;
        left: 85%;
        width: 50px;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.orion {
            top: 1rem;
            left: 2rem;
            width: 18%;
        }
        &.casiope {
            width: 20%;
        }
    }

    @media only screen and (max-width : 767px) {
        &.planet {
            right: 26%;
            top: 2.9rem;
            rotate: -34deg;
            z-index: 1;
            width: 140px;
        }
        &.sun {
            top: 1%;
            left: 77%;
            width: 50px;
            z-index: 1;
        }
        &.orion {
            top: 3rem;
            left: 1rem;
            width: 27%;
            rotate: 141deg;
        }
        &.casiope {
            top: 64%;
            right: 1%;
            rotate: -13deg;
            width: 26%;
        }
        &.bigbear {
            bottom: 1%;
            left: 25%;
            rotate: 57deg;
            width: 23%;
        }
    }
`

const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 67%;
        left: 7%;
    }
    &.two {
        top: 4%;
        left: 31%;
    }
    &.three {
        top: 2%;
        left: 35%;
    }
    &.four {
        top: 9%;
        left: 59%;
    }
    &.five {
        top: 21%;
        left: 72%;
    }
    &.six {
        top: 8%;
        left: 95%;
    }
    &.seven {
        top: 53%;
        left: 2%;
    }
    &.eight {
        top: 36%;
        left: 96%;
    }
    &.nine {
        top: 74%;
        left: 8%;
    }
    &.ten {
        top: 68%;
        left: 64%;
    }
    &.eleven {
        top: 67%;
        left: 90%;
    }
    &.twelve {
        top: 43%;
        left: 82%;
    }
    &.thirteen {
        top: 74%;
        left: 51%;
    }
    &.fourteen {
        top: 34%;
        left: 68%;
    }
    &.fifteen {
        top: 28%;
        left: 2%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.two {
            top: 86%;
            left: 10%;
        }
        &.five {
            top: 78%;
            left: 85%;
        }
        &.eleven {
            top: 74%;
            left: 90%;
        }
        &.twelve {
            top: 43%;
            left: 82%;
        }
        &.thirteen {
            top: 82%;
            left: 90%;
        }
        &.fourteen {
            top: 24%;
            left: 3%;
        }
        &.fifteen {
            top: 28%;
            left: 5%;
        }
    }

    @media only screen and (max-width : 767px) {
        &.two {
            top: 30%;
            left: 31%;
        }
        &.four {
            top: 29%;
            left: 80%;
        }
        &.five {
            top: 18%;
            left: 18%;
        }
        &.ten {
            top: 65%;
            left: 44%;
        }
        &.eleven {
            top: 78%;
            left: 90%;
        }
        &.twelve {
            top: 92%;
            left: 82%;
        }
        &.thirteen {
            top: 90%;
            left: 7%;
        }
        &.fourteen {
            top: 40%;
            left: 93%;
        }
    }
`

const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 7%;
        left: 34%;
    }
    &.two {
        top: 71%;
        left: 10%;
    }
    &.three {
        top: 7%;
        left: 67%;
    }
    &.four {
        top: 71%;
        left: 61%;
    }
    &.five {
        top: 11%;
        left: 97%;
    }
    &.six {
        top: 14%;
        left: 75%;
    }
    &.seven {
        top: 48%;
        left: 96%;
    }
    &.eight {
        top: 5%;
        left: 83%;
    }
    &.nine {
        top: 78%;
        left: 15%;
    }
    &.ten {
        top: 3%;
        left: 49%;
    }
    &.eleven {
        top: 58%;
        left: 97%;
    }
    &.twelve {
        top: 35%;
        left: 1%;
    }
    &.thirteen {
        top: 23%;
        left: 91%;
    }
    &.fourteen {
        top: 75%;
        left: 71%;
    }
    &.fifteen {
        top: 5%;
        left: 66%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.three {
            top: 84%;
        }
    }

    @media only screen and (max-width : 767px) {
        &.one {
            top: 19%;
            left: 2%;
        }
        &.two {
            top: 65%;
            left: 10%;
        }
        &.three {
            top: 64%;
            left: 51%;
        }
        &.four {
            top: 73%;
            left: 88%;
        }
        &.five {
            top: 11%;
            left: 94%;
        }
        &.six {
            top: 17%;
            left: 77%;
        }
        &.eight {
            top: 90%;
            left: 61%;
        }
        &.ten {
            top: 1%;
            left: 49%;
        }
        &.eleven {
            top: 90%;
            left: 88%;
        }
        &.thirteen {
            top: 26%;
            left: 91%;
        }
        &.fourteen {
            top: 92%;
            left: 7%;
        }
        &.fifteen {
            top: 3%;
            left: 66%;
        }
    }
`

function Header() {
    const headerDescriptionRef = useRef(null)
    const headerDescriptionContainerRef = useRef(null)
    const headerCatchphraseRef = useRef(null)
    const headerCatchphraseContainerRef = useRef(null)
    const headerTitleRef = useRef(null)
    const headerTitleContainerRef = useRef(null)

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            headerDescriptionContainerRef.current.style.height = headerDescriptionRef.current.clientHeight + "px"
            headerCatchphraseContainerRef.current.style.height = headerCatchphraseRef.current.clientHeight + "px"
            headerTitleContainerRef.current.style.height = headerTitleRef.current.clientHeight + "px"
        })
        resizeObserver.observe(headerDescriptionRef.current)
        resizeObserver.observe(headerCatchphraseRef.current)
        resizeObserver.observe(headerTitleRef.current)
        return () => resizeObserver.disconnect()
    }, [])

    useGSAP(
        () => {
            const tl = gsap.timeline()
            const mm = gsap.matchMedia()

            tl.from('.text-appear', {
                y: "200%",
                ease: "power4.out",
                delay: 0.5,
                duration: 1.8,
                stagger: {
                  amount: 1
                }
            })
            gsap.from('.sun', {
                y: Math.random() * (10 - 5) + 5,
                ease: "power1.out",
                duration: 1,
                stagger: {
                    amount: 2,
                    yoyo: true,
                    repeat: -1,
                    each: .2
                }
            })
            gsap.to('.planet', {
                motionPath: "M -46.9846 -17.101 a 50 15 20 1 0 93.9693 34.202 a 50 15 20 1 0 -93.9693 -34.202",
                transformOrigin: "50% 50%",
                duration: 7,
                repeat: -1,
                ease: "linear"
            })
            // gsap.from('.planet', {
            //     y: Math.random() * (10 - 5) + 5,
            //     ease: "power1.out",
            //     duration: 1,
            //     delay: .3,
            //     stagger: {
            //         amount: 2,
            //         yoyo: true,
            //         repeat: -1,
            //         each: .2
            //     }
            // })
            
            mm.add("(min-width: 1025px)", () => {
                tl.from('.hero-cta', {
                    y: 80,
                    ease: "power4.out",
                    delay: 0,
                    duration: .5,
                    stagger: {
                      amount: 0.3
                    }
                }, "-=1.2")
            })

            mm.add("(max-width: 1024px)", () => {
                tl.from('.hero-cta', {
                    y: "12rem",
                    ease: "power4.out",
                    delay: 0,
                    duration: .5,
                    stagger: {
                      amount: 0.3
                    }
                }, "-=1.2")
            })

        }
    )

    return (
        <HeaderContainer id="header_section">
            <ContentContainer>
                <TextContainer className="title" ref={headerTitleContainerRef}>
                    <Title className="text-appear" ref={headerTitleRef}>{ i18next.t('hero-title') }</Title>
                </TextContainer>
                <TextContainer className="catchphrase" ref={headerCatchphraseContainerRef}>
                    <Slogan className="text-appear" ref={headerCatchphraseRef}>{ i18next.t('hero-catchphrase') }</Slogan>
                </TextContainer>
                <TextContainer className="description" ref={headerDescriptionContainerRef}>
                    <Text className="text-appear" ref={headerDescriptionRef}>{i18next.t('hero-description')}</Text>
                </TextContainer>
                
                <ButtonsContainer>
                    <HeaderButton className="hero-cta" label={i18next.t("hero-cta-more")} link={"#about_section"} />
                    <HeaderButton className="hero-cta" label={i18next.t("hero-cta-contact")} link={"#contact_section"} />
                </ButtonsContainer>
            </ContentContainer>
            <Image className="planet" src={Planet} alt="image d'une planète"/>
            <Image className="bottom" src={Wave} alt="vague" />
            <Image className="sun" src={sun} alt="image d'une planète" />
            <Image className="orion" src={orion} alt="image d'une constelation" />
            <Image className="casiope" src={casiope} alt="image d'une constelation" />
            <Image className="bigbear" src={bigbear} alt="image d'une constelation" />
            <SmallStarElt className="one"/>
            <SmallStarElt className="two"/>
            <SmallStarElt className="three"/>
            <SmallStarElt className="four"/>
            <SmallStarElt className="five"/>
            <SmallStarElt className="six"/>
            <SmallStarElt className="seven"/>
            <SmallStarElt className="eight"/>
            <SmallStarElt className="nine"/>
            <SmallStarElt className="ten"/>
            <SmallStarElt className="eleven"/>
            <SmallStarElt className="twelve"/>
            <SmallStarElt className="thirteen"/>
            <SmallStarElt className="fourteen"/>
            <SmallStarElt className="fifteen"/>

            <BigStarElt className="one"/>
            <BigStarElt className="two"/>
            <BigStarElt className="three"/>
            <BigStarElt className="four"/>
            <BigStarElt className="five"/>
            <BigStarElt className="six"/>
            <BigStarElt className="seven"/>
            <BigStarElt className="eight"/>
            <BigStarElt className="nine"/>
            <BigStarElt className="ten"/>
            <BigStarElt className="eleven"/>
            <BigStarElt className="twelve"/>
            <BigStarElt className="thirteen"/>
            <BigStarElt className="fourteen"/>
            <BigStarElt className="fifteen"/>
        </HeaderContainer>
    )
}

export default Header