import styled from "styled-components"
import planet from '../assets/planet.svg'
import { useState } from "react"
import i18next from "i18next"

const Container = styled.div`
    position: fixed;
    top: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 101;
    width: 31rem;
`

const ModalContainer = styled.div`
    background-color: #FFBE00;
    padding: 2rem;
    border-radius: 10px;
    position: absolute;
    width: fit-content;
    box-sizing: border-box;
    transition: all 400ms linear;

    &.show {
        transform: translateY(20%);
    }

    &.hidden {
        transform: translateY(-100%);
    }
`

const HeaderContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
`

const Title = styled.h3`
    text-align: center;
`

const Text = styled.p`
    text-align: center;
`

const ImageContainer = styled.div`
    width: 15%;
    padding-right: 10px;
`

const Image = styled.img`
    width: 100%;
`

function Modal({isVisible}) {

    return (
        <Container>
            <ModalContainer className={isVisible ? 'show' : 'hidden'}>
                <HeaderContent>
                    <ImageContainer>
                        <Image src={planet} alt="image d'une planète" />
                    </ImageContainer>
                    <Title>{i18next.t("modal-title")}</Title>
                </HeaderContent>  
                <Text>{i18next.t("modal-text")}</Text>
            </ModalContainer>
        </Container>
    )
}

export default Modal