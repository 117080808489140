import { createGlobalStyle } from "styled-components"

const StyledGlobalStyle = createGlobalStyle`
    :root {
        font-size: 14px;
    }
    body {
        margin: 0;
        min-height: 100vh;
        /* height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom));
        padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left); */
        font-family: 'Montserrat', sans-serif;
        overflow-x: hidden;
    }
    h1, h2, h3, p {
        margin: 0;
    }
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-button-prev, .swiper-button-next {
        color: white;
    }
    .swiper-wrapper {
        padding: 20px 0 0 0;
    }
    .text-appear {
        /* overflow: hidden; */
        position: absolute;
        /* opacity: 0; */
        /* transform: translateY(20px); */
    }
    ::-webkit-scrollbar {
        background: transparent;
        height: 8px;
        width: 8px;
    }
    ::-webkit-scrollbar-thumb {
        border-radius: 0.25rem;
        background: rgb(148 163 184);
    }
    ::-webkit-scrollbar-track {
        background: transparent;
    }

`

function GlobalStyle() {
    return <StyledGlobalStyle />
}

export default GlobalStyle