import { useEffect, useRef, useState } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"

const Container = styled.div`
    position: fixed;
    bottom: 0;
    z-index: 100;
    width: 100%;
`

const Bar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    background-color: rgb(41 41 41);
    width: 100%;
    box-sizing: border-box;
    transition: all 500ms linear;
    position: absolute;
    bottom: -100%;

    &.show {
        transform: translateY(-99%);
    }

    &.hidden {
        transform: translateY(100%);
    }
`

const Text = styled.p`
    color: white;
    font-size: 1.2rem;
    padding-right: 1rem;

    @media only screen and (max-width : 767px) {
        font-size: 1rem;
    }
`

const StyledLink = styled(Link)`
    color: rgb(93 176 237);
    padding-left: 5px;
`

const Button = styled.button`
    border: none;
    cursor: pointer;
    background-color: #FFBE00;
    border-radius: 5px;
    padding: 0.8rem 1.5rem;
    font-weight: 600;
    font-size: 1.1rem;
`

function ConditionsAcceptanceBar() {
    const containerRef = useRef(null)
    const barRef = useRef(null)
    const [isBarShowed, setIsBarShowed] = useState(false)
    let acceptance = null

    try {
        acceptance = localStorage.getItem('acceptance')
    } catch(e) {
        acceptance = false
    }


    useEffect(() => {
        if (containerRef.current) {
            setTimeout(() => {
                containerRef.current.style.height = barRef.current.clientHeight + 'px'
                setIsBarShowed(true)
            }, 500)
        }
    }, [])

    const handleAcceptance = () => {
        setIsBarShowed(false)
        setTimeout(() => {
            try {
                localStorage.setItem('acceptance', true)
            } catch (e) {
                
            }
        }, 500)
    }

    return (
        <>
        { !acceptance && 
        <Container ref={containerRef}>
            <Bar className={isBarShowed ? 'show' : 'hidden'} ref={barRef}>
                <Text>
                    En poursuivant votre navigation sur ce site, vous acceptez notre politique de confidentialité. 
                    <StyledLink to="/privacy-policy" target="_blank">Politique de confidentialité</StyledLink>
                </Text>
                <Button onClick={() => handleAcceptance()}>J'accepte</Button>
            </Bar>
        </Container>
        }
        </>
    )
}

export default ConditionsAcceptanceBar