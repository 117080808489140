import styled from "styled-components"
import { SpaceSection } from "../../utils/Atoms"
import logo from "../../assets/logo-S-removebg-preview.png"
import planet from "../../assets/planet.svg"
import planet2 from "../../assets/planetEvo3.svg"
import planet3 from "../../assets/planetBY.svg"
import planet4 from "../../assets/planetBYR.svg"
import planet5 from "../../assets/planetBYR3R.svg"
import planet6 from "../../assets/planetR.svg"
import orion from "../../assets/orion.svg"
import casiope from "../../assets/casiope.svg"
import bigbear from "../../assets/bigbear.svg"
import { ReactComponent as SmallStar } from "../../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../../assets/bigStar.svg"
import i18next from "i18next"
import SectionTitle from "../SectionTitle"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

const SpaceSectionElt = styled(SpaceSection)`
    margin: 0;
    margin-top: 5rem;

    @media only screen and (max-width: 767px) {
        margin-top: 39rem;
    }
`

const Container = styled.div`
    width: 90%;
    margin: auto;

    &.historical {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media only screen and (max-width : 767px) {
        width: 100%;

        &.historical {
            flex-direction: column;
        }
    }
`

const Historical = styled.div`
    width: 85%;

    @media only screen and (max-width: 767px) {
        width: 100%;
    }
`

const Title = styled.h4`
    color: white;
    font-size: 2rem;
    margin: 0 0 2rem 0;
`

const Paragraph = styled.p`
    color: white;
    font-size:1.2rem;
    margin: 0 0 4rem 0;

    &.about-skill {
        font-size: 1rem;
        text-align: center;
        margin: 0;
    }

    @media only screen and (max-width: 767px) {
        margin: 0 0 1rem 0;
    }
`

const ImageContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 150px;

    &.image {
        margin: auto;
    }
`

const Image = styled.img`
    width: 100%;

    &.orion {
        position: absolute;
        right: 3%;
        top: 86%;
        width: 19%;
        rotate: -156deg;
    }
    &.casiope {
        position: absolute;
        top: 11%;
        right: 65%;
        rotate: 17deg;
        width: 14%;
    }
    &.bigbear {
        position: absolute;
        top: 7%;
        right: 6%;
        rotate: 206deg;
        width: 13%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.bigbear {
            width: 18%;
        }
        &.casiope {
            top: 8%;
            width: 18%;
        }
        &.orion {
            top: 90%;
            rotate: -193deg;
        }
    }

    @media only screen and (max-width: 767px) {
        &.bigbear {
            top: 4%;
            right: 3%;
            rotate: 172deg;
            width: 21%;
        }
        &.casiope {
            top: 3%;
            right: 76%;
            rotate: 44deg;
            width: 25%;
        }
        &.orion {
            top: 95%;
            width: 29%;
            rotate: 150deg;
            max-width: 140px;
        }
    }
`

const SkillsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`

const Skill = styled.article`
    width: 30%;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 1.5rem;
    box-sizing: border-box;
    margin: 0 0 5% 0;
    min-height: 35rem;
    border-radius: 7px;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        width: 47%;
    }

    @media only screen and (max-width : 767px) {
        width: 100%;
        max-height: 33rem;
        min-height: 30rem;
        /* position: absolute;
        left: 0;
        right: 0;
        margin: auto; */
        /* background-color: rgb(19, 6, 51); */
    }
`

const SkillTitle = styled.h5`
    color: white;
    text-align: center;
    font-size: 1.3rem;
    min-height: 46px;
`

const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 15%;
        left: 3%;
    }
    &.two {
        top: 13%;
        left: 10%;
    }
    &.three {
        top: 16%;
        left: 56%;
    }
    &.four {
        top: 10%;
        left: 66%;
    }
    &.five {
        top: 6%;
        left: 50%;
    }
    &.six {
        top: 17%;
        left: 58%;
    }
    &.seven {
        top: 55%;
        left: 2%;
    }
    &.eight {
        top: 27%;
        left: 42%;
    }
    &.nine {
        top: 31%;
        left: 65%;
    }
    &.ten {
        top: 28%;
        left: 73%;
    }
    &.eleven {
        top: 45%;
        left: 97%;
    }
    &.twelve {
        top: 36%;
        left: 1%;
    }
    &.thirteen {
        top: 58%;
        left: 34%;
    }
    &.fourteen {
        top: 57%;
        left: 65%;
    }
    &.fifteen {
        top: 70%;
        left: 95%;
    }
    &.sixteen {
        top: 87%;
        left: 9%;
    }
    &.seventeen {
        top: 91%;
        left: 34%;
    }
    &.eighteen {
        top: 90%;
        left: 53%;
    }
    &.nineteen {
        top: 91%;
        left: 71%;
    }
    &.twenty {
        top: 89%;
        left: 57%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.two {
            top: 13%;
            left: 4%;
        }
        &.three {
            top: 21%;
            left: 56%;
        }
        &.six {
            top: 20%;
            left: 73%;
        }
        &.eight {
            top: 27%;
            left: 49%;
        }
        &.nine {
            top: 28%;
            left: 94%;
        }
        &.ten {
            top: 47%;
            left: 51%;
        }
        &.thirteen {
            top: 67%;
            left: 49%;
        }
        &.fourteen {
            top: 57%;
            left: 50%;
        }
        &.sixteen {
            top: 90%;
            left: 9%;
        }
    }

    @media only screen and (max-width: 767px) {
        &.two {
            top: 6%;
            left: 10%;
        }
        &.three {
            top: 3%;
            left: 56%;
        }
        &.four {
            top: 4%;
            left: 61%;
        }
        &.five {
            top: 7%;
            left: 68%;
        }
        &.six {
            top: 8%;
            left: 11%;
        }
        &.eight {
            top: 26%;
            left: 2%;
        }
        &.nine {
            top: 31%;
            left: 97%;
        }
        &.ten {
            top: 20%;
            left: 97%;
        }
        &.thirteen {
            top: 63%;
            left: 1%;
        }
        &.fourteen {
            top: 58%;
            left: 97%;
        }
        &.sixteen {
            top: 95%;
            left: 9%;
        }
        &.seventeen {
            top: 17%;
            left: 87%;
        }
        &.eighteen {
            top: 94%;
            left: 44%;
        }
        &.nineteen {
            top: 96%;
            left: 25%;
        }
        &.twenty {
            top: 87%;
            left: 98%;
        }
    }
`

const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 9%;
        left: 46%;
    }
    &.two {
        top: 14%;
        left: 63%;
    }
    &.three {
        top: 28%;
        left: 54%;
    }
    &.four {
        top: 27%;
        left: 3%;
    }
    &.five {
        top: 43%;
        left: 35%;
    }
    &.six {
        top: 34%;
        left: 95%;
    }
    &.seven {
        top: 42%;
        left: 98%;
    }
    &.eight {
        top: 51%;
        left: 4%;
    }
    &.nine {
        top: 57%;
        left: 3%;
    }
    &.ten {
        top: 58%;
        left: 66%;
    }
    &.eleven {
        top: 76%;
        left: 2%;
    }
    &.twelve {
        top: 72%;
        left: 3%;
    }
    &.thirteen {
        top: 69%;
        left: 97%;
    }
    &.fourteen {
        top: 57%;
        left: 97%;
    }
    &.fifteen {
        top: 7%;
        left: 9%;
    }
    &.sixteen {
        top: 70%;
        left: 64%;
    }
    &.seventeen {
        top: 89%;
        left: 17%;
    }
    &.eighteen {
        top: 89%;
        left: 37%;
    }
    &.nineteen {
        top: 92%;
        left: 62%;
    }
    &.twenty {
        top: 87%;
        left: 47%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.one {
            top: 5%;
            left: 46%;
        }
        &.three {
            top: 23%;
            left: 66%;
        }
        &.five {
            top: 41%;
            left: 48%;
        }
        &.ten {
            top: 22%;
            left: 88%;
        }
        &.fifteen {
            top: 5%;
            left: 5%;
        }
        &.sixteen {
            top: 79%;
            left: 94%;
        }
        &.twenty {
            top: 94%;
            left: 47%;
        }
    }

    @media only screen and (max-width: 767px) {
        &.one {
            top: 4%;
            left: 35%;
        }
        &.two {
            top: 6%;
            left: 47%;
        }
        &.three {
            top: 14%;
            left: 3%;
        }
        &.four {
            top: 27%;
            left: 2%;
        }
        &.five {
            top: 43%;
            left: 1%;
        }
        &.six {
            top: 34%;
            left: 97%;
        }
        &.eight {
            top: 51%;
            left: 2%;
        }
        &.nine {
            top: 57%;
            left: 1%;
        }
        &.ten {
            top: 23%;
            left: 98%;
        }
        &.fifteen {
            top: 7%;
            left: 88%;
        }
        &.sixteen {
            top: 82%;
            left: 96%;
        }
        &.seventeen {
            top: 94%;
            left: 17%;
        }
        &.eighteen {
            top: 95%;
            left: 53%;
        }
        &.nineteen {
            top: 97%;
            left: 28%;
        }
        &.twenty {
            top: 90%;
            left: 1%;
        }
    }
`

function AboutSection() {
    
    useGSAP(() => {
        setTimeout(() => {
                gsap.fromTo('.historical', {
                    opacity: 0,
                },{
                    duration: 1,
                    delay: 1.5,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: '.historical',
                        start: "top+=45 75%",
                    }
                })
                gsap.fromTo('.domain-title', {
                    opacity: 0,
                },{
                    duration: 1,
                    delay: 1.8,
                    opacity: 1,
                    scrollTrigger: {
                        trigger: '.domain-title',
                        start: "top+=45 75%",
                    }
                })
                gsap.fromTo('.about-skill-container', {
                    x: -100,
                    opacity: 0,
                }, {
                    opacity: 1,
                    x: 0,
                    delay: 1.5,
                    duration: .5,
                    stagger: {
                        amount: 1
                    },
                    scrollTrigger: {
                        trigger: '.about-skills-container',
                        start: "top 65%",
                    }
                })

                gsap.fromTo('.about-skill-planet', {
                    y: -100,
                    opacity: 0
                }, {
                    y: 0,
                    duration: .3,
                    delay: 2,
                    opacity: 1,
                    stagger: {
                        amount: 1
                    },
                    scrollTrigger: {
                        trigger: '.about-skills-container',
                        start: "top 65%",
                    }
                })

                gsap.fromTo('.about-skill-content', {
                    y: -100,
                    opacity: 0
                }, {
                    y: 0,
                    duration: .3,
                    delay: 2,
                    opacity: 1,
                    stagger: {
                        amount: 2
                    },
                    scrollTrigger: {
                        trigger: '.about-skills-container',
                        start: "top 65%",
                    }
                })
        }, 1000)
    })

    const data = [
        {
            image: planet,
            title: i18next.t("skill-one-title"),
            content: i18next.t("skill-one-description")
        },
        {
            image: planet3,
            title: i18next.t("skill-two-title"),
            content: i18next.t("skill-two-description")
        },
        {
            image: planet4,
            title: i18next.t("skill-three-title"),
            content: i18next.t("skill-three-description")
        },
        {
            image: planet2,
            title: i18next.t("skill-four-title"),
            content: i18next.t("skill-four-description")
        },
        {
            image: planet5,
            title: i18next.t("skill-five-title"),
            content: i18next.t("skill-five-description")
        },
        {
            image: planet6,
            title: i18next.t("skill-six-title"),
            content: i18next.t("skill-six-description")
        }
    ]
    return (
        <SpaceSectionElt id="about_section">
            <Image src={orion} className="orion" alt="image d'une constelation" />
            <Image src={casiope} className="casiope" alt="image d'une constelation" />
            <Image src={bigbear} className="bigbear" alt="image d'une constelation" />
            <SmallStarElt className="one"/>
            <SmallStarElt className="two"/>
            <SmallStarElt className="three"/>
            <SmallStarElt className="four"/>
            <SmallStarElt className="five"/>
            <SmallStarElt className="six"/>
            <SmallStarElt className="seven"/>
            <SmallStarElt className="eight"/>
            <SmallStarElt className="nine"/>
            <SmallStarElt className="ten"/>
            <SmallStarElt className="eleven"/>
            <SmallStarElt className="twelve"/>
            <SmallStarElt className="thirteen"/>
            <SmallStarElt className="fourteen"/>
            <SmallStarElt className="fifteen"/>
            <SmallStarElt className="sixteen"/>
            <SmallStarElt className="seventeen"/>
            <SmallStarElt className="eighteen"/>
            <SmallStarElt className="nineteen"/>
            <SmallStarElt className="twenty"/>

            <BigStarElt className="one"/>
            <BigStarElt className="two"/>
            <BigStarElt className="three"/>
            <BigStarElt className="four"/>
            <BigStarElt className="five"/>
            <BigStarElt className="six"/>
            <BigStarElt className="seven"/>
            <BigStarElt className="eight"/>
            <BigStarElt className="nine"/>
            <BigStarElt className="ten"/>
            <BigStarElt className="eleven"/>
            <BigStarElt className="twelve"/>
            <BigStarElt className="thirteen"/>
            <BigStarElt className="fourteen"/>
            <BigStarElt className="fifteen"/>
            <BigStarElt className="sixteen"/>
            <BigStarElt className="seventeen"/>
            <BigStarElt className="eighteen"/>
            <BigStarElt className="nineteen"/>
            <BigStarElt className="twenty"/> 

            <SectionTitle label={i18next.t("about-section-title")} id="about-title" className={"white"} />
            <Container className="historical">
                <Historical>
                    <Title>{i18next.t("about-section-second-title-history")}</Title>
                    <Paragraph>
                        {i18next.t("about-section-history-description")}
                    </Paragraph>
                </Historical>

                <ImageContainer>
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </ImageContainer>
            </Container>
                <Container>
                    <Title className="domain-title">{i18next.t("about-section-second-title-skill")}</Title>
                    <SkillsContainer className="about-skills-container">
                        {data.map((skill, index) => (
                            <Skill key={index} className="about-skill-container">
                                <ImageContainer className="image about-skill-planet">
                                    <Image src={skill.image} alt="image d'une planète" />
                                </ImageContainer>
                                <SkillTitle className="about-skill-content">{skill.title}</SkillTitle>
                                <Paragraph className="about-skill about-skill-content">{skill.content}</Paragraph>
                            </Skill>
                        ))}
                    </SkillsContainer>
                </Container>
        </SpaceSectionElt>
    )
}

export default AboutSection