import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './components/App'
import reportWebVitals from './reportWebVitals'
import GlobalStyle from './utils/GlobalStyle'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { GlobalProvider } from './utils/global'
import CVGPage from './pages/CGVPage'
import LegalNoticePage from './pages/LegalNoticePage'
import PrivacyPolicyPage from './pages/PrivacyPolicyPage'
import HomePage from './pages/HomePage'
import ContactPage from './pages/ContactPage'
import BlogPage from './pages/BlogPage'
import ArticlePage from './pages/ArticlePage'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <HashRouter>
      <GlobalProvider>
        <App>
          <GlobalStyle />
          <Routes>
            <Route path="/" element={ <HomePage /> } />
            <Route path="/contact" element={ <ContactPage /> } />
            <Route path="/cgv" element={ <CVGPage /> } />
            <Route path="/legal-notice" element={ <LegalNoticePage /> } />
            <Route path="/privacy-policy" element={ <PrivacyPolicyPage /> } />
            <Route path="/blog" element={ <BlogPage /> } />
            <Route path="/blog/:articleTitle" element={ <ArticlePage /> } />
          </Routes>
        </App>
      </GlobalProvider>
    </HashRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
