import styled from "styled-components"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import MobileNavigation from "../components/MobileNavigation"
import { GlobalContext } from "../utils/global"
import { useContext } from "react"
import ConditionsAcceptanceBar from "../components/ConditionsAcceptanceBar"
import image from "../assets/pexels-janko-ferlic-590478.jpg"
import planet from "../assets/planetEvo6.svg"
import { ReactComponent as SmallStar } from "../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../assets/bigStar.svg"
import { useGSAP } from "@gsap/react"
import gsap from 'gsap'
import Wave from '../assets/wave.svg'
import { articles } from "../utils/data.js"
import { Link } from "react-router-dom"
import blog from "../assets/blog-1.jpg"
import blog2 from "../assets/blog-2.jpg"

export const Container = styled.div`
    display: flex;
    justify-content: space-around;
    margin: auto;
    max-width: 1500px;
    
    @media only screen and (max-width : 1024px) {
        flex-direction: column;
    }
`

const HeaderContainer = styled.section`
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(180deg, rgba(19,6,51,0.5) 53%, rgba(5,104,190,0.5) 94%);
    }

    @media only screen and (max-width : 767px) {
        margin-top: 78px;
    }
`

const Video = styled.video`
    width: 100vw;
`

const Source = styled.source`
    
`

const HeaderImage = styled.img`
    position: absolute;
    bottom: -1px;
    left: 0;
    transform: rotateY(180deg);
`

const BlogContainer = styled.article`
    width: 70%;
    padding: 3rem 1rem;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 100%;
    }
    @media only screen and (max-width : 767px) {
        width: 100%;
    }
`

export const ArticleProposalContainer = styled.div`
    width: 30%;
    background: linear-gradient(180deg, #130633 90%, #0568BE);
    margin: 3rem 0;
    padding: 5rem 2rem 2rem 2rem;
    box-sizing: border-box;
    height: fit-content;
    max-height: 1200px;
    position: relative;

    @media only screen and (max-width : 1024px) {
        width: 100%;
    }
`

export const ArticleImageContainer = styled.div`
    width: 100%;
    height: 30rem;

    &.proposal {
        height: 15rem;
    }

    &.planet {
        min-width: 7rem;
        width: 7rem;
        height: auto;
    }

    @media only screen and (max-width : 767px) {
        &.planet {
            min-width: 4rem;
            width: 4rem;
        }
    }

    @media only screen and (max-width : 767px) {
        &.proposal {
            height: 22rem;
        }
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        &.proposal {
            height: 12rem;
        }
    }
`

export const ProposalContainer = styled.div`
    max-height: 720px;
    overflow: hidden;
    overflow-y: scroll;

    ::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.4);
    }
    @media only screen and (max-width: 767px) {
        max-height: 406px;
        overflow-x: scroll;
        display: flex;
        justify-content: space-between;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        overflow-x: scroll;
        display: flex;
        justify-content: space-between;
    }
`

export const ProposalTitle = styled.h3`
    color: white;
    font-size: 2rem;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 5rem;
`

export const ArticleImage = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    &.planet {
        object-fit: unset;
    }
`

const BlogTitleContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3rem 0;
    height: fit-content;
`

const BlogTitle = styled.h1`
    margin: 0 3rem;
    text-transform: uppercase;
    font-size: 2.5rem;
    text-align: center;

    @media only screen and (max-width : 767px) {
        margin: 0 1rem;
        font-size: 1.5rem;
    }
`

const BlogContent = styled.p`
    margin-bottom: 2rem;
`

export const ArticleProposal = styled.article`
    
    &:not(:last-child) {
        margin: 0 auto 3rem auto;
    }
    @media only screen and (max-width: 767px) {
        width: 100%;
        min-width: 100%;

        &:not(:last-child) {
            margin: 0 1.5rem 3rem 0;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 30%;
        min-width: 30%;

        &:not(:last-child) {
            margin: 0 1.5rem 2rem 0;
        }
    }
`

export const ArticleLink = styled(Link)`
    text-decoration: none;
    z-index: 1;
    position: relative;
`

export const ArticleProposalTitle = styled.h2`
    color: white;
    text-transform: uppercase;
    margin-top: 1rem;

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        font-size: 1.4rem;
    }
`

const ImagesContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width: 420px) {
        flex-direction: column;
    }
`

const BlogImageContainer = styled.div`
    width: 50%;
`

const BlogImage = styled.img`
    width: 100%;
`

export const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 3rem;
        left: 10%;
    }
    &.two {
        top: 1rem;
        left: 90%;
    }
    &.three {
        top: 1.5rem;
        left: 40%;
    }
    &.four {
        top: 9rem;
        left: 26%;
    }
    &.five {
        top: 7rem;
        left: 84%;
    }
    &.six {
        top: 22rem;
        left: 1%;
    }
    &.seven {
        top: 87%;
        left: 4%;
    }
    &.eight {
        top: 47%;
        left: 97%;
    }
    &.nine {
        top: 70%;
        left: 94%;
    }

    @media only screen and (max-width : 1024px) {
        &.seven {
            top: 87%;
            left: 2%;
        }
        &.nine {
            top: 70%;
            left: 96%;
        }
    }
`

export const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 2.8rem;
        left: 83%;
    }
    &.two {
        top: 2rem;
        left: 15%;
    }
    &.three {
        top: 7.4rem;
        left: 8%;
    }
    &.four {
        top: 3.4rem;
        left: 48%;
    }
    &.five {
        top: 8.8rem;
        left: 69%;
    }
    &.six {
        top: 49%;
        left: 1%;
    }
    &.seven {
        top: 77%;
        left: 4%;
    }
    &.eight {
        top: 24%;
        left: 94%;
    }
    &.nine {
        top: 50%;
        left: 94%;
    }
    &.ten {
        top: 85%;
        left: 96%;
    }

    @media only screen and (max-width : 1024px) {
        &.seven {
            top: 77%;
            left: 1%;
        }
        &.eight {
            top: 24%;
            left: 96%;
        }
        &.nine {
            top: 50%;
            left: 96%;
        }
    }
`

function BlogPage() {
    const { device } = useContext(GlobalContext)
    useGSAP(() => {

        gsap.from('.header-anim', {
            duration: 1,
            delay: .5,
            opacity: 0
        })
        gsap.from('.blog-anim', {
            x: -100,
            duration: 1,
            delay: 1,
            opacity: 0,
            stagger: {
                amount: 1
            }
        })
        gsap.from('.news-anim', {
            duration: 1,
            delay: 2,
            opacity: 0,
        })
    })
    return (
        <>
            {device === 'mobile' && <MobileNavigation />}
            {(device === 'tablet' || device === 'desktop') && <Navigation />}
            <HeaderContainer className="header-anim">
            <Video loop autoPlay muted>
                <Source src={require('../assets/web-boost.mp4')} type="video/mp4" />
            </Video>
                <HeaderImage src={Wave} alt="vague" />
            </HeaderContainer>

            <Container>
                <BlogContainer>
                    <BlogTitleContainer className="blog-anim">
                        <ArticleImageContainer className="planet">
                            <ArticleImage className="planet" src={planet} alt="Image d'une planète" />
                        </ArticleImageContainer>

                        <BlogTitle>Blog</BlogTitle>

                        <ArticleImageContainer className="planet">
                            <ArticleImage className="planet" src={planet} alt="Image d'une planète" />
                        </ArticleImageContainer>
                    </BlogTitleContainer>

                    <BlogContent className="blog-anim">
                        Bienvenue sur notre Blog : Votre Référence pour la Création et la Gestion de Sites Internet
                    </BlogContent>
                    <BlogContent className="blog-anim">
                        Chez Web Boost, nous sommes passionnés par tout ce qui concerne le monde des sites internet. Que vous soyez un entrepreneur cherchant à créer une présence en ligne, un développeur web en quête de nouvelles techniques, ou un propriétaire de site souhaitant optimiser et maintenir votre plateforme, notre blog est la ressource idéale pour vous. 
                    </BlogContent>
                    <BlogContent className="blog-anim">
                        Découvrez nos articles détaillés couvrant un large panel d’aspects sur la création de sites web, la conception initiale à la mise en ligne, en passant par le développement technique, la maintenance continue et les mises à jour régulières. Nous abordons des sujets variés tels que le choix des outils et des technologies, les meilleures pratiques de design UX/UI, les stratégies pour assurer la sécurité et la performance de votre site etc. Plongez dans nos derniers articles et améliorez vos compétences web avec des conseils d'experts, des tutoriels pratiques et des études de cas inspirantes pour transformer vos idées en projets réussis !
                    </BlogContent>
                    <BlogContent className="blog-anim">
                        Notre blog est conçu pour être votre guide dans le monde du numérique. Ce guide vous fournira des informations, des astuces pratiques et des conseils d'experts pour vous aider à créer, développer, maintenir et mettre à jour votre site web avec succès. Restez connectés avec nous pour ne rien manquer des dernières tendances et innovations du secteur.
                    </BlogContent>

                    <ImagesContainer>
                        <BlogImageContainer className="blog-anim">
                            <BlogImage src={ blog } alt="" />
                        </BlogImageContainer>

                        <BlogImageContainer className="blog-anim">
                            <BlogImage src={ blog2} alt="" />
                        </BlogImageContainer>
                    </ImagesContainer>
                </BlogContainer>

                <ArticleProposalContainer className="news-anim">
                    <SmallStarElt className="one"/>
                    <SmallStarElt className="two"/>
                    <SmallStarElt className="three"/>
                    <SmallStarElt className="four"/>
                    <SmallStarElt className="five"/>
                    <SmallStarElt className="six"/>
                    <SmallStarElt className="seven"/>
                    <SmallStarElt className="eight"/>
                    <SmallStarElt className="nine"/>

                    <BigStarElt className="one"/>
                    <BigStarElt className="two"/>
                    <BigStarElt className="three"/>
                    <BigStarElt className="four"/>
                    <BigStarElt className="five"/>
                    <BigStarElt className="six"/>
                    <BigStarElt className="seven"/>
                    <BigStarElt className="eight"/>
                    <BigStarElt className="nine"/>
                    <BigStarElt className="ten"/>

                    <ProposalTitle>A la une</ProposalTitle>

                    <ProposalContainer>
                        {articles.map((art, index) => (
                            <ArticleProposal key={ index }>
                                <ArticleLink to={ `/blog/${ art.urlTitle }` }>
                                    <ArticleImageContainer className="proposal">
                                        <ArticleImage src={ art.image } alt={ art.title } />
                                    </ArticleImageContainer>
                                    <ArticleProposalTitle>{ art.title }</ArticleProposalTitle>
                                </ArticleLink>
                            </ArticleProposal>
                        ))}
                    </ProposalContainer>
                </ArticleProposalContainer>
            </Container>
            <ConditionsAcceptanceBar />
            <Footer />
        </>
    )
}

export default BlogPage