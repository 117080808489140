import styled from "styled-components"
import { ReactComponent as Arrow } from "../assets/arrow-up-svgrepo-com.svg"

const Button = styled.button`
    border: none;
    width: 40px;
    height: 40px;
    position: fixed;
    right: 2rem;
    bottom: 7rem;
    z-index: 100;
    background-color: rgb(225 225 225 / 50%);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        width: 80%;
        height: 80%;
    }
    @media only screen and (max-width: 767px) {
        bottom: 10rem;
    }
`

function ScrollButton () {
    return (
        <Button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth'})}>
            <Arrow />
        </Button>
    )
}

export default ScrollButton