import styled from "styled-components"
import Footer from "../components/Footer"
import logo from "../assets/logo-S-removebg-preview.png"
import { Header, WebBoostLogoContainer, Image, Title, SubTitle, Paragraph, List, WebBoostLink } from "../utils/Atoms"

const Container = styled.div`

`

const ListItem = styled.li`

`

const Strong = styled.strong`

`

function PrivacyPolicyPage() {
    return (
        <Container>
            <Header>
                <WebBoostLogoContainer to="/">
                    <Image src={logo} alt="logo de l'entreprise web boost, c'est un ruban bleu, blanc et rouge, en forme de 'W'."/>
                </WebBoostLogoContainer>

                <WebBoostLink to="/">
                    <Title className="header">Web boost</Title>
                </WebBoostLink>
            </Header>

            <Title className="title">Politique de confidentialité</Title>

            <SubTitle>1. Introduction</SubTitle>
            <Paragraph className="end">
                Web boost s'engage à respecter la vie privée des utilisateurs de son site internet https://web-boost.fr. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles lorsque vous visitez notre site ou utilisez nos services.
            </Paragraph>

            <SubTitle>2. Collecte de données</SubTitle>
            <Paragraph>
                Comme nous n'utilisons pas de cookies, nous collectons uniquement les données personnelles que vous nous fournissez volontairement via les formulaires de notre site internet. Ces données peuvent inclure :
            </Paragraph>
            <List>
                <ListItem>
                    <Strong>Informations de contact : </Strong>
                    Nom, adresse e-mail, numéro de téléphone.
                </ListItem>
                <ListItem>
                    <Strong>Données de formulaire : </Strong>
                    Informations que vous nous fournissez dans des formulaires, comme des demandes de devis ou des inscriptions à la newsletter.
                </ListItem>
            </List>

            <SubTitle>3. Utilisation des données</SubTitle>
            <Paragraph>Nous utilisons vos données personnelles pour :</Paragraph>
            <List>
                <ListItem>
                    <Strong>Fournir nos services : </Strong>
                    Nous utilisons vos données pour traiter vos demandes, répondre à vos questions et vous fournir les services que vous avez demandés.
                </ListItem>
                <ListItem>
                    <Strong>Améliorer notre site web : </Strong>
                    Nous pouvons utiliser vos données pour améliorer l'ergonomie et les fonctionnalités de notre site.
                </ListItem>
                <ListItem>
                    <Strong>Envoyer des communications marketing : </Strong>
                    Nous pouvons utiliser vos données de contact pour vous envoyer des informations sur nos produits et services, des promotions et des actualités, si vous y avez consenti.
                </ListItem>
                <ListItem>
                    <Strong>Respecter nos obligations légales : </Strong>
                    Nous pouvons utiliser vos données pour nous conformer aux lois et réglementations en vigueur.
                </ListItem>
            </List>

            <SubTitle>4. Partage des données</SubTitle>
            <Paragraph>Nous ne partageons vos données personnelles avec des tiers que dans les cas suivants :</Paragraph>
            <List>
                <ListItem>
                    <Strong>Avec votre consentement : </Strong>
                    Nous pouvons partager vos données avec des tiers si vous nous y avez expressément autorisés.
                </ListItem>
                <ListItem>
                    <Strong>Avec des prestataires de services : </Strong>
                    Nous pouvons partager vos données avec des prestataires de services qui nous aident à fournir nos services, comme des hébergeurs web.
                </ListItem>
                <ListItem>
                    <Strong>Pour nous conformer à la loi : </Strong>
                    Nous pouvons partager vos données si la loi nous l'exige, par exemple dans le cadre d'une enquête judiciaire.
                </ListItem>
            </List>
            <Paragraph className="end">
                Nous nous assurons que tous les tiers avec lesquels nous partageons vos données respectent vos données personnelles et mettent en œuvre des mesures de sécurité adéquates pour les protéger.
            </Paragraph>

            <SubTitle>5. Sécurité des données</SubTitle>
            <Paragraph>
                Nous prenons des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données personnelles contre la destruction accidentelle, la perte, l'altération, l'accès non autorisé ou le traitement illicite.
            </Paragraph>
            <Paragraph className="second">
                Parmi ces mesures, citons :
            </Paragraph>
            <List>
                <ListItem>
                    <Strong>Cryptage des données : </Strong>
                    Nous cryptons vos données personnelles lors de leur transmission et de leur stockage.
                </ListItem>
                <ListItem>
                    <Strong>Pare-feu : </Strong>
                    Nous utilisons des pare-feu pour protéger notre site web contre les intrusions.
                </ListItem>
                <ListItem>
                    <Strong>Contrôles d'accès : </Strong>
                    Nous limitons l'accès à vos données personnelles aux personnes qui en ont besoin pour fournir nos services.
                </ListItem>
            </List>

            <SubTitle>6. Droits des utilisateurs</SubTitle>
            <Paragraph>Vous disposez des droits suivants concernant vos données personnelles :</Paragraph>
            <List>
                <ListItem>
                    <Strong>Droit d'accès : </Strong>
                    Vous avez le droit de demander des informations sur vos données personnelles que nous traitons et d'obtenir une copie de celles-ci.
                </ListItem>
                <ListItem>
                    <Strong>Droit de rectification : </Strong>
                    Vous avez le droit de demander la correction de vos données personnelles si elles sont inexactes ou incomplètes.
                </ListItem>
                <ListItem>
                    <Strong>Droit à l'effacement : </Strong>
                    Vous avez le droit de demander l'effacement de vos données personnelles dans certains cas, comme si elles ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées.
                </ListItem>
                <ListItem>
                    <Strong>Droit de limitation du traitement : </Strong>
                    Vous avez le droit de demander la limitation du traitement de vos données personnelles dans certains cas, comme si vous contestez leur exactitude.
                </ListItem>
                <ListItem>
                    <Strong>Droit d'opposition au traitement : </Strong>
                    Vous avez le droit de vous opposer au traitement de vos données personnelles dans certains cas, comme pour des raisons de marketing direct.
                </ListItem>
                <ListItem>
                    <Strong>Droit à la portabilité des données : </Strong>
                    Vous avez le droit de recevoir vos données personnelles dans un format structuré, communément utilisé et lisible par une machine, et de les transmettre à un autre responsable du traitement.
                </ListItem>
            </List>
            <Paragraph>Vous pouvez exercer vos droits en nous contactant à l'adresse contact@web-boost.fr ou à l'adresse postale 57 Rue de la ville 67460 Souffelweyersheim.</Paragraph>

            <Footer />
        </Container>
    )
}

export default PrivacyPolicyPage