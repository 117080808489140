import { GlobalContext } from "../utils/global"
import CustomerSection from "../components/section/CustomerSection"
import { useContext } from "react"
import ProjectSection from "../components/section/ProjectSection"
import StartProjectSection from "../components/section/StartProjectSection"
import AboutSection from "../components/section/AboutSection"
import TechnologiesSection from "../components/section/TechnologiesSection"
import QuestionsSection from "../components/section/QuestionsSection"
import ContactSection from "../components/section/ContactSection"
import MobileNavigation from "../components/MobileNavigation"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import ConditionsAcceptanceBar from "../components/ConditionsAcceptanceBar"
import NewsSection from "../components/section/NewsSection"


function HomePage() {
    const { device } = useContext(GlobalContext)
    return (
        <>
            {device === 'mobile' && <MobileNavigation />}
            {(device === 'tablet' || device === 'desktop') && <Navigation />}
            <Header />
            <CustomerSection />
            <ProjectSection />
            <StartProjectSection />
            <AboutSection />
            <TechnologiesSection />
            <QuestionsSection />
            <NewsSection />
            <ContactSection />
            <Footer />
            <ConditionsAcceptanceBar />
        </>
    )
}

export default HomePage