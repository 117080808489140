import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import planet1 from "../../assets/planetEvo1.svg"
import planet3 from "../../assets/planetEvo3.svg"
import planet6 from "../../assets/planetEvo6.svg"

import i18next from "i18next"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"

const Section = styled.section`

`

const Container = styled.article`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    margin: 0 auto 2rem auto;

    @media only screen and (max-width : 767px) {
        flex-direction: column;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        background-color: #FFF;
        min-height: 480px;
        box-shadow: rgba(14, 63, 126, 0.06) 0px 0px 0px 1px, 
        rgba(42, 51, 70, 0.03) 0px 1px 1px -0.5px, 
        rgba(42, 51, 70, 0.04) 0px 2px 2px -1px, 
        rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, 
        rgba(42, 51, 70, 0.03) 0px 5px 5px -2.5px, 
        rgba(42, 51, 70, 0.03) 0px 10px 10px -5px, 
        rgba(42, 51, 70, 0.03) 0px 24px 24px -8px;
    }
`

const ImageContainer = styled.div`
    width: 200px;
    min-width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        width: 150px;
        min-width: 150px;
    }
`

const Image = styled.img`
    width: 100%;
`

const ContentContainer = styled.div`
    padding: 0 0 0 2rem;
    @media only screen and (max-width : 767px) {
        padding: 0;
        text-align: center;
    }
`

const Title = styled.h4`
    font-size: 2rem;
    color: #130633;
    margin: 0 0 2rem 0;

    &.section {
        margin: 1rem auto;
        width: 90%;
    }

    @media only screen and (max-width: 767px) {
        font-size: 1.7em;
    }
`

const Paragraph = styled.p`
    color: #130633;
    font-size: 1.2rem;

    &.section {
        margin: 1rem auto;
        width: 90%;
    }

    @media only screen and (max-width: 767px) {
        font-size: 1rem;
    }
`

function StartProjectSection() {
    const data = [
        {
            title: i18next.t("first-step-title"),
            content: i18next.t("first-step-description"),
            logo: planet1
        },
        {
            title: i18next.t("second-step-title"),
            content: i18next.t("second-step-description"),
            logo: planet3
        },
        {
            title: i18next.t("last-step-title"),
            content: i18next.t("last-step-description"),
            logo: planet6
        }
    ]

    useGSAP(() => {
        const mm = gsap.matchMedia()
        mm.add("(max-width: 767px)", () => {
            gsap.fromTo(
                ".start-project-card:not(:first-of-type)",
                {
                    y: () => "200%",
                },
                {
                    y: 0,
                    stagger: 0.5,
                    scrollTrigger: {
                        pin: ".start-project-container",
                        //markers: true,
                        scrub: true,
                        start: "top 6%",
                        end: "+=2000",
                        invalidateOnRefresh: true
                    }
                }
            )
        })

        mm.add("(min-width: 768px)", () => {
            gsap.from('.start-project-planet', {
                duration: 1,
                delay: 1,
                opacity: 0,
                stagger: {
                    amount: .5
                },
                scrollTrigger: {
                    trigger: '.start-project-container',
                    start: "top 65%",
                }
            })

            gsap.from('.start-project-title', {
                duration: 1,
                delay: 2,
                opacity: 0,
                stagger: {
                    amount: .5
                },
                scrollTrigger: {
                    trigger: '.start-project-container',
                    start: "top 65%",
                }
            })

            gsap.from('.start-project-desc', {
                duration: 1.5,
                delay: 3,
                opacity: 0,
                stagger: {
                    amount: .5
                },
                scrollTrigger: {
                    trigger: '.start-project-container',
                    start: "top 65%",
                }
            })
        })
    })
      

    return (
        <Section className="start-project-container">
            <SectionTitle label={ i18next.t("start-project-section-title") } id={ 'start-project-title' } />
            {data.map((step, index) => (
                <Container className={ "start-project-card" } key={ index }>
                    <ImageContainer className="start-project-planet">
                        <Image src={ step.logo } alt="image d'une planète" />
                    </ImageContainer>

                    <ContentContainer>
                        <Title className="start-project-title">{ step.title }</Title>
                        <Paragraph className="start-project-desc">{ step.content }</Paragraph>
                    </ContentContainer>
                </Container>
            ))}
        </Section>
    )
}

export default StartProjectSection