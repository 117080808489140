import styled from "styled-components"
import SectionTitle from "../SectionTitle"
import FoldableBar from "../FoldableBar"
import { ReactComponent as Chevron } from '../../assets/chevron.svg'
import { useState } from "react"
import blueRingPlanet from '../../assets/blueRingPlanet.svg'
import orion from "../../assets/orion.svg"
import casiope from "../../assets/casiope.svg"
import bigbear from "../../assets/bigbear.svg"
import { ReactComponent as SmallStar } from "../../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../../assets/bigStar.svg"
import i18next from "i18next"
import { useGSAP } from "@gsap/react"
import gsap from "gsap"
import AstronautSvg from "../AstronautSvg"

const Section = styled.section`
    display: flex;
    margin: 4rem 0;

    @media only screen and (max-width : 767px) {
        flex-direction: column-reverse;
        text-align: center;
        margin: 4rem 0 0 0;
    }
`

const ContentContainer = styled.div`
    width: 75%;
    padding: 0 4rem 5rem 4rem;
    box-sizing: border-box;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 0 2rem 2rem 2rem;
    }

    @media only screen and (max-width : 767px) {
        width: 100%;
        padding: 1rem;
    }
`

const DrawingContainer = styled.div`
    width: 35%;
    background: linear-gradient(180deg, #0568BE, #130633 36%);
    position: relative;

    @media only screen and (max-width : 767px) {
        width: 100%;
        height: 400px;
    }
`

const Span = styled.span`
    font-size: 1.8rem;

    @media only screen and (max-width : 767px) {
        font-size: 1.2rem;
    }
`

const QuestionsContainer = styled.div`
    margin: auto;
    min-height: 46rem;
    
    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        min-height: 47rem;
    }

    @media only screen and (max-width : 767px) {
        min-height: auto;
    }
`
const AstronautContainer = styled.div`
    position: absolute;
    bottom: 7%;
    width: 70%;
    left: 15%;
    z-index: 1;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        bottom: 10%;
        width: 92%;
        left: 5%;
    }

    @media only screen and (max-width: 767px) {
        width: 66%;
        max-width: 210px;
        left: 26%;
    }
`

const Image = styled.img`
position: absolute;

    &.orion {
        top: 42%;
        left: 58%;
        width: 42%;
        rotate: -86deg;
    }
    &.casiope {
        top: 6%;
        left: 25%;
        width: 42%;
    }
    &.bigbear {
        top: 65%;
        left: -1%;
        width: 29%;
        rotate: -31deg;
    }

    @media only screen and (max-width: 767px) {
        &.bigbear {
            top: 57%;
            max-width: 130px;
        }
        &.casiope {
            max-width: 160px;
        }
    }
`

const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 31%;
        left: 10%;
    }
    &.two {
        top: 27%;
        left: 35%;
    }
    &.three {
        top: 46%;
        left: 23%;
    }
    &.four {
        top: 19%;
        left: 90%;
    }
    &.five {
        top: 94%;
        left: 10%;
    }
    &.six {
        top: 73%;
        left: 90%;
    }
    &.seven {
        top: 85%;
        left: 80%;
    }
    &.eight {
        top: 88%;
        left: 86%;
    }
    &.nine {
        top: 24%;
        left: 78%;
    }
    &.ten {
        top: 16%;
        left: 10%;
    }

    @media only screen and (max-width: 767px) {
        &.two {
            top: 91%;
            left: 35%;
        }
    }
`

const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 36%;
        left: 24%;
    }
    &.two {
        top: 97%;
        left: 19%;
    }
    &.three {
        top: 22%;
        left: 88%;
    }
    &.four {
        top: 95%;
        left: 27%;
    }
    &.five {
        top: 92%;
        left: 74%;
    }
    &.six {
        top: 32%;
        left: 50%;
    }
    &.seven {
        top: 23%;
        left: 40%;
    }
    &.eight {
        top: 95%;
        left: 51%;
    }
    &.nine {
        top: 3%;
        left: 90%;
    }
    &.ten {
        top: 52%;
        left: 5%;
    }

    @media only screen and (max-width: 767px) {
        &.two {
            top: 14%;
            left: 19%;
        }
        &.six {
            top: 5%;
            left: 5%;
        }
        &.seven {
            top: 18%;
            left: 65%;
        }
    }
`

function QuestionsSection() {
    const [foldableShowedIndex, setFoldableShowedIndex] = useState(null)
    useGSAP(() => {
        gsap.from('.span', {
            x: -100,
            duration: 1,
            delay: 1,
            opacity: 0,
            stagger: {
                amount: 1
            },
            scrollTrigger: {
                trigger: '.questions-container',
                start: "top 65%",
            }
        })

        gsap.from('.question-container', {
            x: -100,
            duration: 1,
            delay: 1,
            opacity: 0,
            stagger: {
                amount: 1
            },
            scrollTrigger: {
                trigger: '.questions-container',
                start: "top 65%",
            }
        })
    })

    const data = [
        {
            title: i18next.t("question-one"),
            content: i18next.t("question-one-answer")
        },
        {
            title: i18next.t("question-two"),
            content: i18next.t("question-two-answer")
        },
        {
            title: i18next.t("question-three"),
            content: i18next.t("question-three-answer")
        },
        {
            title: i18next.t("question-four"),
            content: i18next.t("question-four-answer")
        },
        {
            title: i18next.t("question-five"),
            content: i18next.t("question-five-answer")
        }
    ]
    return (
        <Section>
            <ContentContainer>
                <SectionTitle label={i18next.t("question-section-title")} id="questions-title" />
                <Span className="span">{i18next.t("question-catchphrase")}</Span>

                <QuestionsContainer className="questions-container">
                    {data.map((question, index) => (
                        <FoldableBar 
                            title={question.title}
                            content={question.content}
                            icon={<Chevron />}
                            isVisible={foldableShowedIndex === index}
                            setIsVisible={setFoldableShowedIndex}
                            index={index}
                            key={index}
                            image={blueRingPlanet}
                            className={"question-container"}
                        />
                    ))}
                </QuestionsContainer>
            </ContentContainer>

            <DrawingContainer>
                <AstronautContainer>
                    <AstronautSvg />
                </AstronautContainer>
                <Image src={orion} className="orion" alt="image d'une constelation" />
                <Image src={casiope} className="casiope" alt="image d'une constelation" />
                <Image src={bigbear} className="bigbear" alt="image d'une constelation" />
                <SmallStarElt className="one"/>
                <SmallStarElt className="two"/>
                <SmallStarElt className="three"/>
                <SmallStarElt className="four"/>
                <SmallStarElt className="five"/>
                <SmallStarElt className="six"/>
                <SmallStarElt className="seven"/>
                <SmallStarElt className="eight"/>
                <SmallStarElt className="nine"/>
                <SmallStarElt className="ten"/>
                <BigStarElt className="one"/>
                <BigStarElt className="two"/>
                <BigStarElt className="three"/>
                <BigStarElt className="four"/>
                <BigStarElt className="five"/>
                <BigStarElt className="six"/>
                <BigStarElt className="seven"/>
                <BigStarElt className="eight"/>
                <BigStarElt className="nine"/>
                <BigStarElt className="ten"/>
            </DrawingContainer>
        </Section>
    )
}

export default QuestionsSection