import { useGSAP } from "@gsap/react"
import gsap from "gsap"
import { useEffect, useRef } from "react"
import styled from "styled-components"

const TitleContainer = styled.div`
    overflow: hidden;
    height: 3rem;
    line-height: 3rem;
    position: relative;
    margin: 5rem auto;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        margin: 3rem 1rem;
    }
    @media only screen and (max-width : 767px) {
        margin: 3rem 1rem;
    }
    &.not-margin {
        margin: auto;
    }
`
const Title = styled.h3`
    width: 100%;
    font-size: 3rem;
    text-align: center;
    color: #130633;
    position: absolute;

    &.white {
        color: #ffffff;
    }
    @media only screen and (max-width : 767px) {
        font-size: 2rem;
    }

`

function SectionTitle ({ label, id, className, classContainer }) {
    const sectionTitleRef = useRef(null)
    const sectionTitleContainerRef = useRef(null)

    useEffect(() => {
        setTimeout(() => {
            sectionTitleContainerRef.current.style.height = sectionTitleRef.current.offsetHeight + "px"
        })
    }, [])

    useGSAP(() => {
        gsap.from(`#${id}`, {
            y: 130,
            ease: "power4.out",
            duration: 1.8,
            scrollTrigger: {
              trigger: `#${id}`,
              start: "top 75%",
            //   markers: true
            }
        })
      })
    return (
        <TitleContainer ref={sectionTitleContainerRef} className={classContainer}>
            <Title id={id} ref={sectionTitleRef} className={className}>{label}</Title>
        </TitleContainer>
    )
}

export default SectionTitle