import MobileNavigation from "../components/MobileNavigation"
import { GlobalContext } from "../utils/global"
import { useContext, useEffect, useRef, useState } from "react"
import Footer from "../components/Footer"
import Navigation from "../components/Navigation"
import ConditionsAcceptanceBar from "../components/ConditionsAcceptanceBar"
import styled from "styled-components"
import codeur from '../assets/codeur.svg'
import phone from '../assets/phone.svg'
import location from '../assets/location-pin-svgrepo-com.svg'
import arobase from '../assets/arobase.svg'
import malt from '../assets/malt.svg'
import bark from "../assets/bark.webp"
import i18next from "i18next"
import { ReactComponent as SmallStar } from "../assets/smallStar.svg"
import { ReactComponent as BigStar } from "../assets/bigStar.svg"
import planet from "../assets/planetBYR3R.svg"
import planet2 from "../assets/planetBY.svg"
import emailjs from '@emailjs/browser'
import Modal from "../components/Modal"

const Container = styled.div`
    display: flex;
    max-width: 1400px;
    margin: auto;

    @media only screen and (max-width : 767px) {
        flex-direction: column;
    }
`

const FormContainer = styled.div`
    width: 65%;
    box-sizing: border-box;
    padding: 3rem 2rem 3rem 4rem;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 3rem 2rem;
    }

    @media only screen and (max-width : 767px) {
        width: 100%;
        padding: 9rem 1rem 3rem 1rem;
    }
`

const Form = styled.form`
    padding: 3rem 9rem;
    background: linear-gradient(180deg, #130633 93%, #0568BE);
    border-radius: 10px;
    position: relative;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 3rem 4.6rem
    }

    @media only screen and (max-width: 767px) {
        padding: 3rem;
    }
`

const InputContainer = styled.div`
    position: relative;
    margin: 0 auto 2.7rem auto;

    &.message {
        padding: 1.7rem 0 0 0;
    }
`

const Label = styled.label`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: fit-content;
    color: white;

    &.message {
        bottom: auto;
    }

    &:has(+ input:focus), &:has(+ textarea:focus) {
        color: #FFBE00; 
    }
`

const Input = styled.input`
    width: 100%;
    border: none;
    border-bottom: solid 1px white;
    box-sizing: border-box;
    padding: 1rem 1rem 1rem 7rem;
    background-color: #130633;
    color: white;
    font-size: 1.3rem;

    &:-webkit-autofill:focus, &:-webkit-autofill {
        transition: background-color 0s 600000s, color 0s 600000s !important;
    }

    &:focus {
        outline: none;
        border-bottom: solid 1px #FFBE00;
    }
`

const Textarea = styled.textarea`
    width: 100%;
    height: 10rem;
    box-sizing: border-box;
    background-color: #130633;
    color: white;
    resize: none;
    border: white 1px solid;
    padding: 1rem;
    font-size: 1.3rem;

    &:focus {
        outline: none;
        border: solid 1px #FFBE00;
    }
`

const ContactsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 35%;
    padding: 3rem 3rem 3rem 0;
    box-sizing: border-box;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        padding: 3rem 2rem 3rem 0;
        flex-direction: column;
    }

    @media only screen and (max-width : 767px) {
        width: 100%;
        padding: 0 1rem 3rem 1rem;
    }
`

const Contact = styled.article`
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px 3px rgba(0, 0, 0, 0.21);
    border-radius: 30px;
    height: fit-content;
    transform: scale(1);
    transition: transform 300ms linear;
    min-height: 13rem;

    &:hover {
        transform: scale(1.05);
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        width: 100%;
        min-height: auto;

        &.address {
            padding: 2rem 1rem;
            flex-direction: row;
            justify-content: flex-start;
        }
    }

    @media only screen and (max-width : 767px) {
        width: 100%;
        margin: 1rem;
        min-height: auto;

        &.address {
            padding: 1rem 0.5rem;
        }
    }
`

const ImageContainer = styled.div`
    width: 40px;
    height: 40px;
    margin: 0 auto 1rem auto;

    &.planet {
        position: absolute;
        top: 0rem;
        right: 4rem;
        margin: 0;
        width: 7rem;
        z-index: 1;
    }
    &.secondPlanet {
        position: absolute;
        bottom: 5rem;
        left: 1rem;
        margin: 0;
        width: 6rem;
        z-index: 1;
    }
    &.contact {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.planet {
            top: 1rem;
            right: 1rem;
            width: 5rem;
        }

        padding-right: 10px;
        margin: 0;
    }
`

const Image = styled.img`
    width: 100%;
`

const ContactLink = styled.a`
    text-decoration: none;
    color: #130633;
    padding: 3rem .5rem;
    text-align: center;
    width: 100%;
    box-sizing: border-box;

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 2rem 1rem;
    }

    @media only screen and (max-width: 767px) {
        padding: 1rem .5rem;
    }
`

const Text = styled.span`
    text-align: center;
    
    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        text-align: start;
    }
`

const ErrorMessage = styled.span`
    color: red;
    font-size: 0.8rem;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -22px;
    z-index: 1;

    &.name{
        bottom: -35px;
    }

    @media only screen and (max-width: 767px) {
        &.name{
            bottom: -44px;
        }
        &.phone {
            bottom: -35px;
        }
    }
`

const Title = styled.h2`
    color: white;
    text-align: center;
    margin: 1rem 0;
    position: relative;
    z-index: 1;

    @media only screen and (max-width: 767px) {
        margin: 4rem 0 1rem 0;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

const Button = styled.button`
    border: 2px solid #FFBE00;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 25px;
    background: #FFBE00;
    color: #130633;
    z-index: 1;
    cursor: pointer;

    &:hover {
        background: white;
        color: #FFBE00;
        transition: all 250ms linear;
    }
`

const SmallStarElt = styled(SmallStar)`
    position: absolute;

    &.one {
        top: 21%;
        left: 5%;
    }
    &.two {
        top: 10%;
        left: 17%;
    }
    &.three {
        top: 6%;
        left: 67%;
    }
    &.four {
        top: 3%;
        left: 74%;
    }
    &.five {
        top: 27%;
        left: 91%;
    }
    &.six {
        top: 90%;
        left: 91%;
    }
    &.seven {
        top: 91%;
        left: 31%;
    }
    &.eight {
        top: 60%;
        left: 94%;
    }
    &.nine {
        top: 27%;
        left: 6%;
    }
    &.ten {
        top: 97%;
        left: 64%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px){
        &.seven {
            top: 91%;
            left: 25%;
        }
    }
`

const BigStarElt = styled(BigStar)`
    position: absolute;

    &.one {
        top: 94%;
        left: 37%;
    }
    &.two {
        top: 4%;
        left: 31%;
    }
    &.three {
        top: 2%;
        left: 35%;
    }
    &.four {
        top: 93%;
        left: 78%;
    }
    &.five {
        top: 62%;
        left: 92%;
    }
    &.six {
        top: 8%;
        left: 95%;
    }
    &.seven {
        top: 53%;
        left: 2%;
    }
    &.eight {
        top: 36%;
        left: 96%;
    }
    &.nine {
        top: 74%;
        left: 8%;
    }
    &.ten {
        top: 6%;
        left: 4%;
    }

    @media only screen and (min-width : 768px) and (max-width : 1024px) {
        &.six {
            top: 49%;
            left: 7%;
        }
    }
`

function ContactPage() {
    const [formErrors, setFormErrors] = useState({
        name: null,
        phone: null,
        email: null,
        message: null
    })
    const [isVisibleModal, setIsVisibleModal] = useState(false)

    const { device } = useContext(GlobalContext)
    const headerRef = useRef(null)
    const footerRef = useRef(null)
    const containerRef = useRef(null)
    const formRef = useRef(null)

    useEffect(() => {
        if (headerRef.current?.clientHeight && footerRef.current?.clientHeight) {
            containerRef.current.style.minHeight = window.innerHeight - (headerRef.current?.clientHeight + footerRef.current?.clientHeight) +'px'
        }
    }, [device])

    const sendEmail = (e) => {
        e.preventDefault()
        
        if (!validateForm()) {
            return;
        }

        emailjs
            .sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formRef.current, {
                publicKey: process.env.REACT_APP_PUBLIC_KEY,
              })
              .then(
                () => {
                    console.log('SUCCESS!')
                    document.contact_form.reset()
                    setIsVisibleModal(true)
                    setTimeout(() => {
                        setIsVisibleModal(false)
                    }, 3500)
                },
                (error) => {
                    console.log('FAILED...', error.text)
                },
            )
    }

    const validateForm = () => {
        const regexNames = /^[A-Za-zÀ-ÿ\s-]{2,}$/
        const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
        const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/

        const [name,, email, phone, message] = formRef.current
        const formErrorsCopy = {...formErrors}
        let isValid = true

        let errorMessage = ''

        if(!regexNames.test(name.value)) {
            errorMessage = "Ce champ doit contenir uniquement des lettres, tiret ou espace et au moins deux caractères."
            isValid = false
        } else if(name.value === "") {
            errorMessage = "Ce champ ne doit pas être vide."
            isValid = false
        } else {
            errorMessage = ""
        }
        formErrorsCopy.name = errorMessage

        if(!regexEmail.test(email.value)) {
            errorMessage = "L'email n'est pas valide"
            isValid = false
        } else if(email.value === "") {
            errorMessage = "Ce champ ne doit pas être vide."
            isValid = false
        } else {
            errorMessage = ""
        }
        formErrorsCopy.email = errorMessage

        if(!regexPhone.test(phone.value)) {
            errorMessage = "Le numéro de téléphone n'est pas valide."
            isValid = false
        } else if(phone.value === "") {
            errorMessage = "Ce champ ne doit pas être vide."
            isValid = false
        } else {
            errorMessage = ""
        }
        formErrorsCopy.phone = errorMessage

        if(message.value === "") {
            errorMessage = "Ce champ ne doit pas être vide."
            isValid = false
        } else {
            errorMessage = ""
        }
        formErrorsCopy.message = errorMessage

        setFormErrors(formErrorsCopy)

        return isValid
    }

    return (
        <>
            {device === 'mobile' && <MobileNavigation />}
            {(device === 'tablet' || device === 'desktop') && <Navigation ref={headerRef} />}
            <Modal isVisible={isVisibleModal}/>
            <Container ref={containerRef}>
                <FormContainer>
                    <Form ref={formRef} onSubmit={sendEmail} name="contact_form">
                        <ImageContainer className="planet">
                            <Image src={planet} alt="image d'une planète" />
                        </ImageContainer>
                        <ImageContainer className="secondPlanet">
                            <Image src={planet2} alt="image d'une planète" />
                        </ImageContainer>
                        <SmallStarElt className="one"/>
                        <SmallStarElt className="two"/>
                        <SmallStarElt className="three"/>
                        <SmallStarElt className="four"/>
                        <SmallStarElt className="five"/>
                        <SmallStarElt className="six"/>
                        <SmallStarElt className="seven"/>
                        <SmallStarElt className="eight"/>
                        <SmallStarElt className="nine"/>
                        <SmallStarElt className="ten"/>

                        <BigStarElt className="one"/>
                        <BigStarElt className="two"/>
                        <BigStarElt className="three"/>
                        <BigStarElt className="four"/>
                        <BigStarElt className="five"/>
                        <BigStarElt className="six"/>
                        <BigStarElt className="seven"/>
                        <BigStarElt className="eight"/>
                        <BigStarElt className="nine"/>
                        <BigStarElt className="ten"/>

                        <Title>{i18next.t("contact-page-form-title")}</Title>
                        <InputContainer>
                            <Label htmlFor="inputName">{i18next.t("contact-page-form-input-name")}</Label>
                            <Input type="text" id="inputName" name="name"/>
                            <ErrorMessage className="name">{ formErrors.name }</ErrorMessage>
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor="inputCompany">{i18next.t("contact-page-form-input-company")}</Label>
                            <Input type="text" id="inputCompany" name="company"/>
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor="inputEmail">{i18next.t("contact-page-form-input-email")}</Label>
                            <Input type="text" id="inputEmail" name="email"/>
                            <ErrorMessage>{ formErrors.email }</ErrorMessage>
                        </InputContainer>

                        <InputContainer>
                            <Label htmlFor="inputPhone">{i18next.t("contact-page-form-input-phone")}</Label>
                            <Input type="text" id="inputPhone" name="phone"/>
                            <ErrorMessage className="phone">{ formErrors.phone }</ErrorMessage>
                        </InputContainer>

                        <InputContainer className="message">
                            <Label className="message" htmlFor="inputMessage">{i18next.t("contact-page-form-input-message")}</Label>
                            <Textarea id="inputMessage" name="message"></Textarea>
                            <ErrorMessage>{ formErrors.message }</ErrorMessage>
                        </InputContainer>

                        <ButtonContainer>
                            <Button type="submit">{i18next.t("contact-page-form-button-send")}</Button>
                        </ButtonContainer>
                        
                    </Form>
                </FormContainer>

                <ContactsContainer>
                    <Contact>
                        <ContactLink href="tel:0786524195">
                            <ImageContainer>
                                <Image src={phone} alt="logo téléphone"/>
                            </ImageContainer>
                            <Text>07 86 52 41 95</Text>
                        </ContactLink>
                    </Contact>

                    <Contact>
                        <ContactLink href="mailto:contact@web-boost.fr">
                            <ImageContainer>
                                <Image src={arobase} alt="logo email"/>
                            </ImageContainer>
                            <Text>contact@web-boost.fr</Text>
                        </ContactLink>
                    </Contact>

                    <Contact>
                        <ContactLink href="https://www.codeur.com/-webboostfr" target="_blank">
                            <ImageContainer>
                                <Image src={codeur} alt="logo plateforme codeur" />
                            </ImageContainer>
                            <Text>{i18next.t("contact-section-codeur")}</Text>
                        </ContactLink>
                    </Contact>

                    <Contact>
                        <ContactLink href="https://www.malt.fr/profile/julien3" target="_blank">
                            <ImageContainer>
                                <Image src={malt} alt="logo plateforme malt"/>
                            </ImageContainer>
                            <Text>{i18next.t("contact-section-malt")}</Text>
                        </ContactLink>
                    </Contact>

                    <Contact className="address">
                        <ImageContainer className="contact">
                            <Image src={location} alt="logo localisation"/>
                        </ImageContainer>
                        <Text>57 Rue de la ville, 67460 Souffelweyersheim</Text>
                    </Contact>

                    <Contact>
                        <ContactLink href="https://www.bark.com/fr/fr/b/web-boost/aRE8w/" target="_blank">
                            <ImageContainer className="contact">
                                <Image src={bark} alt="logo plateforme bark"/>
                            </ImageContainer>
                            <Text>{i18next.t("contact-section-bark")}</Text>
                        </ContactLink>
                    </Contact>
                </ContactsContainer>
            </Container>
            <Footer ref={footerRef} />
            <ConditionsAcceptanceBar />
        </>
    )
}

export default ContactPage